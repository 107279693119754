.App {
  text-align: center;
}

.App-header {
  background-color: #75787e3f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(20, 19, 19);
}


.App-header span {
  font-size: 1rem;
  display: inline;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chart {
  width: 80%;
}
